import React, {Component} from 'react';
import {init, registerIndicator, registerOverlay, getFigureClass} from "klinecharts";

//https://klinecharts.com/en-US/guide/introduction
//https://klinecharts.com/en-US/guide/instance-api
//https://klinecharts.com/en-US/guide/chart-api
class CandleStick extends Component {
    constructor(props) {
        super(props);
        this.params = new URLSearchParams(window.location.search);
        const interval = this.params.get("interval");
        const symbol = this.params.get("symbol");
        this.state = {
            data: [],
            timeIndexMap: Map,
            loading: true,
            chart: null,
            interval: interval,
            symbol: symbol,
            allSymbols: [],
        };
    }

    componentDidMount() {
        this.fetchData();
        fetch(`/api/candle/symbols`)
            .then(response => response.json())
            .then(data => {
                this.setState({ allSymbols: data});
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        this.chart = init('chart');

        const max = ['↓']
        const min = ['↑']
        registerIndicator({
            name: 'max_min',
            figures: [
                { key: 'max' },
                { key: 'min' }
            ],
            calc: (kLineDataList) => {
                return kLineDataList.map(kLineData => ({ extremumType: kLineData.extremumType,
                    price:  kLineData.open > kLineData.close ? kLineData.low : kLineData.high + 1}))
            },
            draw: ({
                       ctx,
                       barSpace,
                       visibleRange,
                       indicator,
                       xAxis,
                       yAxis
                   }) => {
                const { from, to } = visibleRange

                ctx.font = barSpace.gapBar + 'px' + ' Helvetica Neue'
                ctx.textAlign = 'center'
                ctx.fillStyle = 'black'
                const result = indicator.result
                for (let i = from; i < to; i++) {
                    const data = result[i]
                    if (data.extremumType === null) {
                        continue;
                    }
                    const x = xAxis.convertToPixel(i);
                    const y = yAxis.convertToPixel(data.price);
                    const text = data.extremumType === 'HIGH_EXTREMUM' ? max[0] : min[0];
                    ctx.fillText(text, x, y)
                }
                return false
            }
        });
    }

    fetchData = () => {
        const {interval, symbol} = this.state;
        fetch(`/api/candle?interval=${interval}&symbol=${symbol}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data, loading: false });
                const map = new Map();
                data.klines.forEach((klineData, index) => {
                    map.set(klineData.startTime, index);
                });
                this.setState({timeIndexMap: map})
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ loading: false });
            });
    };


    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({ [id]: value }, () => {
            this.updateURL(this.state.interval, this.state.symbol);
            this.fetchData(this.state.interval, this.state.symbol); // Повторный вызов логики
        });
    };

    updateURL(interval, symbol) {
        const params = new URLSearchParams(window.location.search);
        params.set('interval', interval);
        params.set('symbol', symbol);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }

    render() {
        const {data, loading, timeIndexMap} = this.state;

        if (loading) {
            return <>
                <div id="chart" style={{width: window.innerWidth - 100, height: window.innerHeight - 100}}/>
            </>
        }

        if (data.error !== undefined) {
            return <div>Данных для такого интервала нету</div>
        }

        const dataList = []
        data.klines.map(item => {
            const open = item.openPrice
            const high = item.highPrice
            const low = item.lowPrice
            const close = item.closePrice
            const timestamp = item.startTime
            const closeTime = item.closeTime
            const extremumType = item.extremumType
            dataList.push({timestamp, open, high, low, close, closeTime, extremumType})
        });

        registerOverlay({
            name: 'lso_overlay',
            lock: true,
            visible: true,
            zLevel: 1,
            createPointFigures: ({bounding, xAxis, yAxis }) => {
                let lso = data.lso.map((data, index) => {
                    if (data.startTime === null || data.startTime === undefined) {
                        return;
                    }
                    const color = data.color === null || data.color === undefined ? 'black' : data.color;
                    const xPixel = xAxis.convertToPixel(timeIndexMap.get(data.startTime));
                    const x1Pixel = data.endTime === null || data.endTime === undefined
                        ? bounding.width
                        : xAxis.convertToPixel(timeIndexMap.get(data.endTime));
                    const yPixel = yAxis.convertToPixel(data.price);

                    return {
                        type: 'line',
                        attrs: [{coordinates: [{x: xPixel, y: yPixel}, {x: x1Pixel, y: yPixel}]}],
                        styles: {color: color}
                    };
                }).filter(data => data != null);

                let uds = data.uds.map((data, index) => {
                    if (data.startTime === null || data.startTime === undefined) {
                        return;
                    }
                    const color = data.color === null || data.color === undefined ? 'black' : data.color;
                    const xPixel = xAxis.convertToPixel(timeIndexMap.get(data.startTime));
                    const x1Pixel = data.endTime === null || data.endTime === undefined
                        ? bounding.width
                        : xAxis.convertToPixel(timeIndexMap.get(data.endTime));
                    const yPixel = yAxis.convertToPixel(data.price);

                    return {
                        type: 'line',
                        attrs: [{coordinates: [{x: xPixel, y: yPixel}, {x: x1Pixel, y: yPixel}]}],
                        styles: {color: color}
                    };
                }).filter(data => data != null);

                let flats = data.flats.map((data, index) => {
                    if (data.points === null || data.points === undefined) {
                        return;
                    }
                    const color = data.color === null || data.color === undefined ? 'black' : data.color;

                    const points = data.points;
                    const x1Pixel = xAxis.convertToPixel(timeIndexMap.get(points.first.startTime));
                    const y1Pixel = yAxis.convertToPixel(points.first.price);

                    const x2Pixel = xAxis.convertToPixel(timeIndexMap.get(points.second.startTime));
                    const y2Pixel = yAxis.convertToPixel(points.second.price);

                    const x3Pixel = xAxis.convertToPixel(timeIndexMap.get(points.third.startTime));
                    const y3Pixel = yAxis.convertToPixel(points.third.price);

                    const x4Pixel = xAxis.convertToPixel(timeIndexMap.get(points.fourth.startTime));
                    const y4Pixel = yAxis.convertToPixel(points.fourth.price);

                    return {
                        type: 'line',
                        attrs: [{coordinates: [
                                {x: x1Pixel, y: y1Pixel},
                                {x: x2Pixel, y: y2Pixel},
                                {x: x3Pixel, y: y3Pixel},
                                {x: x4Pixel, y: y4Pixel},
                                {x: x1Pixel, y: y1Pixel},
                            ]
                        }],
                        styles: {color: color}
                    };
                }).filter(data => data != null);
                return lso.concat(flats).concat(uds);
            }
        });

        this.chart.applyNewData(dataList);
        this.chart.createIndicator("max_min", true, { id: 'candle_pane' });
        this.chart.createOverlay([{
            id: "lso_overlay",
            name: "lso_overlay",
            paneId: "lso_overlay",
            groupId: "overlay",
            visible: true,
            zLevel: 1
        }]);
        //this.chart.scrollToTimestamp(1653127200000) //Сдвиг на определенную точку

        var maData = [5, 10, 30, 60];
        var smaData = [12, 2];
        var emaData = [6, 12, 20];
        if (data.indicators !== null) {
            maData = data.indicators.MA ? data.indicators.MA.values : [5, 10, 30, 60];
            smaData = data.indicators.SMA ? data.indicators.SMA.values : [12, 2];
            emaData = data.indicators.EMA ? data.indicators.EMA.values : [6, 12, 20];
        }

        return (
            <>
                <div id="chart"
                     style={{width: window.innerWidth - 100, height: window.innerHeight - 100}}/>

                <button onClick={() => {
                    this.chart.createOverlay("horizontalSegment")
                }}>Прямая линия
                </button>
                <div/>
                <button onClick={() => {
                    this.chart.createIndicator("max_min", true, {id: 'candle_pane'})
                }}>Вкл. Максимумы
                </button>
                <button onClick={() => {
                    this.chart.removeIndicator("candle_pane", "max_min")
                }}>Выкл. Максимумы
                </button>
                <div/>


                {/*<button onClick={() => {this.chart.createIndicator("lso", true, { id: 'candle_pane' })}}>Вкл. LSO</button>*/}

                <button onClick={() => {
                    this.chart.createOverlay("lso_overlay")
                }}>Вкл. LSO/FLAT
                </button>
                <button onClick={() => {
                    this.chart.removeOverlay({name: "lso_overlay"})
                }}>Выкл. LSO/FLAT
                </button>
                <div/>
                <button onClick={() => {
                    this.chart.createIndicator({name: 'MA', calcParams: maData}, true, {id: 'candle_pane'})
                }}>MA
                </button>
                <button onClick={() => {
                    this.chart.removeIndicator("candle_pane", "MA")
                }}>Выкл. MA
                </button>
                <div/>
                <button onClick={() => {
                    this.chart.createIndicator({name: 'EMA', calcParams: emaData}, true, {id: 'candle_pane'})
                }}>EMA
                </button>
                <button onClick={() => {
                    this.chart.removeIndicator("candle_pane", "EMA")
                }}>Выкл. EMA
                </button>
                <div/>
                <button onClick={() => {
                    this.chart.createIndicator({name: 'SMA', calcParams: smaData}, true, {id: 'candle_pane'})
                }}>SMA
                </button>
                <button onClick={() => {
                    this.chart.removeIndicator("candle_pane", "SMA")
                }}>Выкл. SMA
                </button>

                <div/>
                <select id="interval" value={this.state.interval} onChange={this.handleChange}>
                    {/*<option value="1m">1 minute</option>*/}
                    {/*<option value="3m">3 minute</option>*/}
                    <option value="5m">5 minute</option>
                    {/*<option value="15m">15 minute</option>*/}
                    {/*<option value="30m">30 minute</option>*/}

                    <option value="1h">1 hour</option>
                    {/*<option value="2h">2 hour</option>*/}
                    <option value="4h">4 hour</option>
                    {/*<option value="6h">6 hour</option>*/}
                    {/*<option value="8h">8 hour</option>*/}
                    {/*<option value="12h">12 hour</option>*/}

                    <option value="1d">1 day</option>
                    {/*<option value="3d">3 day</option>*/}
                    <option value="1w">1 week</option>
                    {/*<option value="1M">1 month</option>*/}
                </select>

                <select id="symbol" value={this.state.symbol} onChange={this.handleChange}>
                    {this.state.allSymbols.map((symbol) => (
                        <option key={symbol} value={symbol}>
                            {symbol}
                        </option>
                    ))}
                </select>
            </>
        );
    }

}

export default CandleStick;