import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import CandleStick from "./CandleStick";

export const SimpleRoutes = () => {
    return (
        <Routes>
            <Route path='/candle' element={<CandleStick />} />

            <Route path='/' exact={true} element={<Navigate replace to='/candle?interval=1h&symbol=BNBUSDT' />} />
        </Routes>
    );
}