import React from "react";
import { BrowserRouter } from "react-router-dom";
import {SimpleRoutes} from "./SimpleRoutes";

function App() {
    const routes = SimpleRoutes();

    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    );
}

export default App;
